<template>
  <div class="flix-navbar flix-form-group" v-if="user !== false">
    <ul class="flix-nav flix-nav-tabs">
      <li class="flix-html-li"><router-link :to="{name: 'dashboardProfile', params: {id: 'profile'}}" class="flix-html-a"><span><flixIcon :id="'user'" /></span> {{ $t('message.personalData') }}</router-link></li>
      <li class="flix-html-li"><router-link :to="{name: 'dashboardProfile', params: {id: 'imprint'}}" class="flix-html-a"><span v-if="user.imprint"><flixIcon :id="'paragraph-alt'" /></span><span v-else class="flix-text-danger"><flixIcon :id="'warning-sign'" /></span> {{ $t('message.imprint') }}</router-link></li>
      <li class="flix-html-li"><router-link :to="{name: 'dashboardProfile', params: {id: 'privacy'}}" class="flix-html-a"><span v-if="user.privacy"><flixIcon :id="'police'" /></span><span v-else class="flix-text-danger"><flixIcon :id="'warning-sign'" /></span> {{ $t('message.privacy') }}</router-link></li>
      <li class="flix-html-li"><router-link :to="{name: 'dashboardProfile', params: {id: 'terms'}}" class="flix-html-a"><flixIcon :id="'list'" /> {{ $t('message.terms') }}</router-link></li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      user: false,
      variables: this.$getUserVariables()
    }
  },
  methods: {

  },
  mounted () {
    this.$flix_post({
      url: 'user/get_imprint',
      data: {
        user: this.variables.user.md5_id
      },
      callback: function (ret) {
        this.user = ret.data[1]
      }.bind(this)
    })
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-nav > .flix-html-li > .flix-html-a:hover, .flix-nav > .flix-html-li > .flix-html-a.router-link-active
    background-color: #8DC044
    color: white
</style>
